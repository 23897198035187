// MUI
import { Box } from '@mui/material';

interface Props {
    pageNumber?: number;
}

const HomeBackground = ( {
    pageNumber = 1
}: Props ) => (
    <Box
        sx={ {
            position: 'fixed'
            , bottom: 0
            , zIndex: -1
            , width: '100%'
            , height: '100vh'
        } }
    >
        <Box
            component='svg'
            version='1.1'
            xmlns='https://www.w3.org/2000/svg'
            viewBox='0 0 1440 900'
            preserveAspectRatio='xMidYMid slice'
            sx={ {
                width: '100%'
                , height: '100%'
            } }
        >
            <Box component='defs'>
                <Box
                    component='linearGradient'
                    x1='80.5603038%'
                    y1='48.2807094%'
                    x2='5.56688298%'
                    y2='48.8180604%'
                    id='linearGradient-1'
                >
                    <Box
                        component='stop'
                        stopColor='#68AAAB'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#2182C3'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='0%'
                    y1='49.3313358%'
                    x2='81.3768171%'
                    y2='48.5533412%'
                    id='linearGradient-1b'
                >
                    <Box
                        component='stop'
                        stopColor='#8871DB'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#2081C3'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='80.5603038%'
                    y1='48.2807094%'
                    x2='5.56688298%'
                    y2='48.8180604%'
                    id='linearGradient-2'
                >
                    <Box
                        component='stop'
                        stopColor='#68AAAB'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#2182C3'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='0%'
                    y1='49.3313358%'
                    x2='81.3768171%'
                    y2='48.5533412%'
                    id='linearGradient-2b'
                >
                    <Box
                        component='stop'
                        stopColor='#8C99D1'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#8072DA'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='80.5603038%'
                    y1='48.2807094%'
                    x2='5.56688298%'
                    y2='48.8180604%'
                    id='linearGradient-3'
                >
                    <Box
                        component='stop'
                        stopColor='#68AAAB'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#2182C3'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='0%'
                    y1='49.3313358%'
                    x2='81.3768171%'
                    y2='48.5533412%'
                    id='linearGradient-3b'
                >
                    <Box
                        component='stop'
                        stopColor='#BF8DC5'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#D55EA5'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='80.5603038%'
                    y1='48.2807094%'
                    x2='5.56688298%'
                    y2='48.8180604%'
                    id='linearGradient-4'
                >
                    <Box
                        component='stop'
                        stopColor='#CD60AB'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#AF8EC6'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='0%'
                    y1='49.3313358%'
                    x2='81.3768171%'
                    y2='48.5533412%'
                    id='linearGradient-4b'
                >
                    <Box
                        component='stop'
                        stopColor='#896BD4'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#7C6FD2'
                        offset='60%'
                    />
                    <Box
                        component='stop'
                        stopColor='#2785C5'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='80.5603038%'
                    y1='48.2807094%'
                    x2='5.56688298%'
                    y2='48.8180604%'
                    id='linearGradient-5'
                >
                    <Box
                        component='stop'
                        stopColor='#2081C3'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#2081C3'
                        offset='60%'
                    />
                    <Box
                        component='stop'
                        stopColor='#3E7DCA'
                        offset='70%'
                    />
                    <Box
                        component='stop'
                        stopColor='#8B73DB'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='0%'
                    y1='49.3313358%'
                    x2='81.3768171%'
                    y2='48.5533412%'
                    id='linearGradient-5b'
                >
                    <Box
                        component='stop'
                        stopColor='#BF8DC5'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#5D99CE'
                        offset='75%'
                    />
                    <Box
                        component='stop'
                        stopColor='#00A4D7'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='80.5603038%'
                    y1='48.2807094%'
                    x2='5.56688298%'
                    y2='48.8180604%'
                    id='linearGradient-6'
                >
                    <Box
                        component='stop'
                        stopColor='#8C73DB'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#6278D2'
                        offset='30%'
                    />
                    <Box
                        component='stop'
                        stopColor='#2081C3'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='0%'
                    y1='49.3313358%'
                    x2='81.3768171%'
                    y2='48.5533412%'
                    id='linearGradient-6b'
                >
                    <Box
                        component='stop'
                        stopColor='#8B6EDC'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#AC67C3'
                        offset='75%'
                    />
                    <Box
                        component='stop'
                        stopColor='#D45EA5'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='80.5603038%'
                    y1='48.2807094%'
                    x2='5.56688298%'
                    y2='48.8180604%'
                    id='linearGradient-10'
                >
                    <Box
                        component='stop'
                        stopColor='#2081C3'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#3E7DCA'
                        offset='25.2290728%'
                    />
                    <Box
                        component='stop'
                        stopColor='#8D73DB'
                        offset='100%'
                    />
                </Box>
                <Box
                    component='linearGradient'
                    x1='0%'
                    y1='49.3313358%'
                    x2='81.3768171%'
                    y2='48.5533412%'
                    id='linearGradient-10b'
                >
                    <Box
                        component='stop'
                        stopColor='#BF8DC5'
                        offset='0%'
                    />
                    <Box
                        component='stop'
                        stopColor='#BF8DC5'
                        offset='4.3627796%'
                    />
                    <Box
                        component='stop'
                        stopColor='#5D99CE'
                        offset='80.1111721%'
                    />
                    <Box
                        component='stop'
                        stopColor='#0084B7'
                        offset='100%'
                    />
                </Box>
            </Box>
            <Box
                component='g'
                id='background-triangle'
                fill={ `url(#linearGradient-${ pageNumber })` }
                opacity='0.97'
            >
                <Box
                    component='path'
                    d='M0,900 L0,800 L200,650 L380,750 L700,550 L950,620 L1300,300 L1440 400 L1440,900 Z'
                    id='Path-10--2'
                />
            </Box>
            <Box
                component='g'
                id='foreground-triangle'
                fill={ `url(#linearGradient-${ pageNumber }b)` }
                opacity='0.97'
            >
                <Box
                    component='path'
                    d='M0,900 L0,900 L200,750 L380,850 L700,650 L950,720 L1300,400 L1440 500 L1440,900 Z'
                    id='Path-10--2'
                />
            </Box>
        </Box>
    </Box>
);

export default HomeBackground;
