// MUI
import { SxProps } from '@mui/material';

export const legalLinkStyles: SxProps = {
    '&:hover': {
        textDecoration: 'underline'
    }
    , color: 'common.white'
    , textDecoration: 'none'
};
