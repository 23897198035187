'use client';
// Libraries
import Image from 'next/legacy/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

// MUI
import {
    Box
    , Fade
    , Grow
    , Hidden
    , Stack
    , Typography
} from '@mui/material';

// Utils
import { pxrem } from '@/utils/pxrem';

// Components
import HomeBackground from '@/layouts/HomeBackground/HomeBackground';

// Styles
import { legalLinkStyles } from './styles';

const TRANSITION_DURATION = 800;
const currentYear = new Date().getFullYear();

interface Props {
    showOperatorInfo?: boolean;
    hideBackground?: boolean;
    marginTop?: number;
    children?: any;
}

type Logo = {
    isCentered?: boolean;
    marginTop?: number;
};

const LoginLayout = ( {
    children
    , showOperatorInfo = true
    , hideBackground = false
    , marginTop = undefined
}: Props ) => {

    if ( children && Array.isArray( children ) ) children = children.filter( ( child: any ) => child );

    const leftChildComponent = children && children?.length > 1 ? children[ 0 ] : null;
    const rightChildComponent = leftChildComponent ? children[ 1 ] : children;

    const pathname = usePathname();

    const VeryableLogo = ( {
        isCentered
        , marginTop
    }: Logo ) => (
        <Box
            sx={ [
                isCentered ?
                    {
                        width: pxrem( 200 )
                        , height: pxrem( 40 )
                        , textAlign: 'center'
                        , margin: {
                            md: `${ pxrem( -50 ) } auto ${ pxrem( 25 ) }`
                            , xs: `${ pxrem( -30 ) } auto ${ pxrem( 25 ) }`
                        }
                    }
                    : { width: pxrem( 250 ) }
                , ( marginTop === 0 ) || !!marginTop ? { marginTop: pxrem( marginTop ) } : {}
            ] }
        >
            <Image
                src='/vry_logo.png'
                alt='Veryable, Inc.'
                width={ 1246 }
                height={ 257 }
            />
        </Box>
    );

    return (
        <Box component='main'>
            <Stack
                sx={ {
                    minHeight: '100vh'
                    , justifyContent: 'center'
                    , position: 'relative'
                    , margin: pxrem( 0, 12.8 )
                    , paddingBottom: pxrem( 32 )
                    , marginTop: {
                        xs: pxrem( 32 )
                        , md: 0
                    }
                } }
            >
                <Stack
                    direction='row'
                    sx={ [
                        leftChildComponent ?
                            {
                                width: '100%'
                                , alignItems: 'flex-start'
                                , zIndex: 2
                                , marginTop: pxrem( 40 )
                                , justifyContent: {
                                    xs: 'center'
                                    , md: 'space-evenly'
                                }
                            }
                            : {
                                width: '100%'
                                , justifyContent: 'center'
                                , zIndex: 2
                            }
                    ] }
                >
                    { /* Left Child */ }
                    <Grow
                        in={ leftChildComponent !== null }
                        timeout={ TRANSITION_DURATION }
                    >
                        <Box>
                            <Hidden mdDown>
                                { leftChildComponent
                                    && (
                                        <Fade in>
                                            <Box
                                                sx={ {
                                                    position: 'relative'
                                                    , height: pxrem( 640 )
                                                    , width: pxrem( 352 )
                                                } }
                                            >
                                                <Stack
                                                    sx={ {
                                                        position: 'fixed'
                                                        , justifyContent: 'space-evenly'
                                                        , minHeight: pxrem( 640 )
                                                        , maxWidth: pxrem( 352 )
                                                        , paddingBottom: pxrem( 100 )
                                                    } }
                                                >
                                                    <Box sx={ { width: pxrem( 250 ) } }>
                                                        <VeryableLogo />
                                                    </Box>
                                                    { leftChildComponent }
                                                </Stack>
                                            </Box>
                                        </Fade>
                                    )
                                }
                            </Hidden>
                        </Box>
                    </Grow>

                    { /* Right Child */ }
                    <Grow
                        in={ rightChildComponent !== null }
                        timeout={ leftChildComponent
                            ? {
                                enter: TRANSITION_DURATION / 2
                                , exit: TRANSITION_DURATION
                            }
                            : 500 }
                    >
                        <Stack
                            sx={ {
                                marginBottom: {
                                    xs: pxrem( 48 )
                                    , md: 'auto'
                                }
                            } }
                        >
                            { leftChildComponent
                                ? (
                                    <Hidden mdUp>
                                        <VeryableLogo isCentered />
                                    </Hidden>
                                )
                                : (
                                    <VeryableLogo
                                        isCentered
                                        marginTop={ marginTop }
                                    />
                                )
                            }
                            <Box
                                sx={ [
                                    {
                                        backgroundColor: hideBackground ? 'none' : 'common.white'
                                        , boxShadow: hideBackground ? 'none' : pxrem( 0, 3, 7 )
                                        , fontSize: pxrem( 14 )
                                        , borderRadius: pxrem( 2 )
                                    }
                                    , ( process.env.NEXT_PUBLIC_ENVIRONMENT
                                        && process.env.NEXT_PUBLIC_ENVIRONMENT === 'demo' ) ?
                                        { marginTop: pxrem( 10 ) }
                                        : {}
                                ] }
                            >
                                { rightChildComponent }
                            </Box>
                            { rightChildComponent && showOperatorInfo
                                && (
                                    <Typography
                                        variant='h6'
                                        sx={ {
                                            margin: pxrem( 0, 0, 5 )
                                            , textAlign: 'center'
                                            , marginTop: pxrem( 19.2 )
                                            , marginBottom: pxrem( 40 )
                                            , color: 'common.white'
                                        } }
                                    >
                                    Not a Business? Click&nbsp;
                                        <Box
                                            component='a'
                                            sx={ {
                                                color: 'common.white'
                                                , cursor: 'pointer'
                                            } }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            href='https://veryableops.com/operators'
                                        >
                                        here
                                        </Box>
                                    &nbsp;to find work.
                                    </Typography>
                                )
                            }
                        </Stack>
                    </Grow>
                </Stack>
                <Fade
                    in
                    timeout={ TRANSITION_DURATION }
                >
                    <Typography
                        variant='caption'
                        sx={ {
                            padding: pxrem( 24 )
                            , textAlign: 'center'
                            , position: 'absolute'
                            , width: '100%'
                            , bottom: 0
                            , color: 'common.white'
                        } }
                    >
                        {
                            pathname === '/'
                                ? (
                                    <Link
                                        href='/login'
                                        legacyBehavior
                                        style={ { textDecoration: 'none' } }
                                    >
                                        <Typography
                                            variant='caption'
                                            sx={ {
                                                '&:hover': {
                                                    textDecoration: 'underline'
                                                }
                                                , margin: 'auto'
                                                , maxWidth: 'max-content'
                                                , color: 'common.white'
                                                , textDecoration: 'none'
                                                , cursor: 'pointer'
                                                , marginBottom: pxrem( 10 )
                                            } }
                                        >
                                    Go To Login
                                        </Typography>
                                    </Link>
                                )
                                : null
                        }
                        <Typography variant='caption'>
                    &nbsp;&nbsp;&copy; 2016 - { currentYear } Veryable, Inc. All rights reserved.
                    &nbsp;|&nbsp;
                        </Typography>
                        <Box
                            component='a'
                            sx={ legalLinkStyles }
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://veryableops.com/privacy-policy'
                        >
                        Privacy Policy
                        </Box>
                        &nbsp;|&nbsp;
                        <Box
                            component='a'
                            sx={ legalLinkStyles }
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://veryableops.com/terms-of-service-businesses'
                        >
                        Terms
                        </Box>
                    </Typography>
                </Fade>
            </Stack>
            <HomeBackground />
        </Box>
    );
};

export default LoginLayout;
